$color: #e20613;

.topbar {
  display: flex;
  flex-direction: row;
  height: 64px;

  justify-content: center;

  // background: $color;
}

.topbar-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  max-width: 800px;
}

.logo {
  height: 100%;
  width: auto;
}