.introduction-text {
  text-align: center;
  display: block;
  margin: 1rem 0;
  font-size: 14pt;

  @media (max-height: 500px) {
      line-height: 14pt;
  }

  @media (max-width: 400px) {
      font-size: 12pt;
  }
}