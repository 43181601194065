@use '../../styles/functions.scss' as *;

.item-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  min-height: 0px;
}

.introduction-text {
  text-align: center;
  display: block;
  margin: 1rem 0;
  font-size: 14pt;

  @media (max-height: 500px) {
      line-height: 14pt;
  }

  @media (max-width: 400px) {
      font-size: 12pt;
  }
}

.item-title-inner {
  font-weight: 700;
  font-size: 18pt;
  color: hulder-color('primary');

  text-align: center;

  @media (max-height: 600px) {
    font-size: 12pt;
  }

  overflow-y: auto;
  text-overflow: ellipsis;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressBar {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 500px;
}

.submitButton {
  display: block;
  margin: inherit;
}

.remarks-field {
  &:focus {
    outline: none;
  }

  max-height: 100%;
  transition: max-height 150ms ease-in-out;

  @media(max-height: 600px) {
    &:not(:focus) {
      max-height: 32px;
    }
  }
}

.center-text {
  text-align: center;
}