@use '@hulder/client-common/styles/functions' as *;

.selector-container {
  border: 2px solid hulder-color('primary');
  border-radius: 4px;
}

.container {
  display: flex;
  flex-direction: column;

  row-gap: 8px;
}