@use '@hulder/client-common/styles/functions.scss' as *;

.completion-page-body {
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;

  @media (max-width: 400px) {
    font-size: 10pt;
  }

  color: hulder-color('primary');
}

.apple-anim {
  width: 160px;
  height: 160px;

  transition: transform 200ms ease-in-out;
}

.apple-anim.hidden {
  transform: scale(0);
}