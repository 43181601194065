.container {
  padding: 12px;
}

.register-form {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  align-items: center;

  row-gap: 8px;
  max-width: 400px;
}

.register-form-name-field {
  width: 100%;
  text-align: center;
}

.voting {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  align-items: center;
  max-width: 800px;
  row-gap: 8px;
}