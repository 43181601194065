@use '../../styles/functions.scss' as *;

.container {
    display: flex;
    flex-direction: row;
}

.checkbox-input {
    display: none;
}

.checkbox-check {
    width: 120px;
    height: 120px;

    @media (max-height: 680px) {
        width: 80px;
        height: 80px;
    }

    polygon {
        fill: white;
    }

    circle {
        fill: #888;
        transition: fill 200ms ease-in;
    }
}

.checkbox-input:checked ~ .checkbox-check {
    circle {
        fill: hulder-color('primary');
    }
}