.container {
  margin: 0px auto;
  box-sizing: border-box;

  padding: 8px 12px;

  &.md {
    width: 100vw;
    max-width: 800px;
  }
}