.skeleton {
  background: #eee;

  width: 256px;
  height: 256px;
}

.qr-image {
  width: 256px;
  height: 256px;
}