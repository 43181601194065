@use '../styles/functions.scss' as *;

.button {
    font-size: 14pt;
    text-align: center;
    background-color: hulder-color('primary');
    border: unset;
    padding: 0.5rem;
    border-radius: 0.4rem;
    cursor: pointer;
    color: #ffffff;
    min-width: 10rem;
    transition: background-color 150ms ease;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%), 0 2px 2px 0 rgb(0 0 0 / 24%);
    -moz-appearance: none;
    -webkit-appearance: none;
    font-family: "Archivo Narrow";
    outline: unset;
    outline-color: #4d90fe;

    &:hover:not(:disabled) {
        background-color: mix(white, hulder-color('primary'), 30%);
    }
}

.button:focus {
    outline-style: auto;
    outline-width: 5px;
}

.button:disabled {
    background-color: #bdbdbd;
}

.color-grey {
    background-color: #bdbdbd;
}

.progress {
    width: auto;
    height: 100%;
}

@media only screen and (min-width: 800px) {
    .default {
        font-size: 12pt;
    }
}
