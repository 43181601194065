@use '../styles/functions.scss' as *;

$color: hulder-color('primary');

.dot {
    fill: $color;
    cursor: pointer;
}

.dot-highlight {
    fill: none;
    stroke-width: 2;
    stroke: $color;
}

.axis {
    stroke: adjust-color($color, $alpha: -0.7);
    stroke-width: 2;
}

.ring {
    fill: none;
    stroke: adjust-color($color, $alpha: -0.7);
    stroke-width: 2;
}

.area {
    fill: adjust-color($color, $alpha: -0.7);
    stroke: $color;
    stroke-width: 2;
}

.chart {
    margin-left: auto;
    margin-right: auto;
    max-width: 512px;
}

.chart > svg {
    width: 100%;
    height: auto;
}