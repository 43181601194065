@use '../styles/functions.scss' as *;
@use './Carousel.module.scss';

.questionnaire-summary-list {
    list-style: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
}

.questionnaire-summary-list-container {
    flex-grow: 1;
    min-width: 250px;
}

.questionnaire-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
}

.vote-detail-remarks {
    padding-left: 12px;
    margin: 8px;
    color: #333;

    &::before {
        content: "\201c";
    }

    &::after {
        content: "\201d";
    }
}

.tab-nav {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid hulder-color('primary');
}

.tab {
    font-family: inherit;
    font-size: inherit;
    padding: 12px;
    appearance: none;
    background-color: white;
    border: none;

    transition: background-color 100ms ease-in;

    &.active {
        background-color: hulder-color('primary');
        color: white;
    }

    &:hover {
        &.active {
            background-color: mix(white, hulder-color('primary'), 30%);
        }
        &:not(.active) {
            background-color: mix(white, hulder-color('primary'), 90%);
        }
    }
}

@mixin vote-detail {
    display: flex;
    flex-direction: column;
    padding: 8px;

    :nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
            margin-left: 8px;
        }
    }

    transition: background-color 100ms ease-in;

    &.highlight {
        background-color: rgba($color: hulder-color('primary'), $alpha: 0.05);
    }
}

.vote-quality-detail {
    @include vote-detail;
}

.vote-participant-detail {
    @include vote-detail;
}

.vote-count-badge {
    width: 24px;
    height: 24px;

    background-color: hulder-color('primary');
    color: whitesmoke;
    border-radius: 50%;

    text-align: center;
}

.chart-container {
    display: flex;
    flex-direction: column;

    align-items: center;

    row-gap: 8px;
}



.questionnaire-summary-list-item {
    &.highlight .header {
        background-color: rgba($color: hulder-color('primary'), $alpha: 0.05);
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 8px;

        padding: 12px;

        background-color: white;
        transition: background-color 100ms ease-in;

        border-top: 1px solid hulder-color('primary');

        :first-child {
            flex-grow: 1;
        }

        * {
            flex-grow: 0;
        }

        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;

            > svg {
                width: 32px;
                height: 32px;
            }
        }
    }

    .detail-list {
        list-style: none;
        padding: 12px;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;

        max-height: var(--max-height, 300px);

        transition: max-height 100ms ease-in, padding 100ms ease-in;

        .vote-check {
            width: 24px;
            height: 24px;
            fill: gray;

            &.checked {
                fill: hulder-color('primary');
            }
        }
    }

    &:not(.expanded) .detail-list {
        max-height: 0;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.chart-quality-carousel {
    height: 80px;
    max-width: 400px;
    width: 100%;
}

.chart-quality-carousel-arrow {
  width: 24px;
  height: 24px;
}

.chart-quality-carousel-item {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    > span {
        text-align: center;
    }
}

.chart-vote-count-summary {
    display: flex;
    flex-direction: row;

    column-gap: 4px;

    font-size: 16pt;
    color: hulder-color('primary');
}