@import '@hulder/client-common/components/QuestionnaireSummary.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: start;

  row-gap: 8px;

  @media(max-width: 600px) {
    align-items: center;
  }
}

.title {
  @media(max-width: 600px) {
    text-align: center;
  }
}

.qr-hint {
  @media(max-width: 600px) {
    text-align: center;
  }
}

.results-container {
  width: 100%;
}

.refresh-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  column-gap: 8px;
}