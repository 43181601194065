@use '../styles/functions.scss' as *;

.container {
    height: 10px;
    background-color: #bdbdbd;
    display: flex;
    position: relative;

    margin-top: 8px;
    margin-bottom: 8px;
}

.progression {
    width: 0%;
    background-color: hulder-color('primary');
    height: 100%;
    transition: all .2s;
}