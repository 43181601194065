@use '../styles/functions.scss' as *;

.carousel-slides {
    width: 100%;
    display: flex;
    flex-direction: row;

    overscroll-behavior: none;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;

    touch-action: none;
}

.carousel-slides::-webkit-scrollbar {
    display: none;
}

.carousel-item {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    min-width: 0px;

    > * {
        flex: 1 1 auto;

        &:not(:first-child) {
            margin-top: 12px;
        }

        &:nth-child(1) {
            flex-basis: calc(35% - 12px);
            @media(max-height: 600px) {
                flex-grow: 0;
                flex-basis: calc(25% - 12px);
            }
        }

        &:nth-child(2), :nth-child(3) {
            flex-basis: calc(27% - 12px);
        }
    }
}

.carousel {
    display: flex;
    flex-direction: row;
    justify-content: center;

    height: 100vh;
    max-height: 450px;
    width: 100%;

    @media (max-height: 800px) {
        max-height: 360px;
    }

    @media (max-height: 600px) {
        max-height: 250px;
    }

    @media (max-height: 450px) {
        max-height: 220px;
    }
}

@mixin arrow {
    position: relative;
    width: 48px;
    height: 48px;
    top: 57%;

    @media (max-width: 600px) {
        width: 40px;
        height: 40px;
    }

    circle {
        fill: hulder-color('primary');
    }
    path {
        fill: white;
    }
}

.arrow-left {
    @include arrow;

    transform: translateY(-50%);
}
.arrow-right {
    @include arrow;

    transform: translateY(-50%) rotateZ(180deg);
}

.carousel-chevron-space {
    display: flex;
    flex-direction: column;

    transition: opacity 100ms ease-in;
}

.no-snap {
    scroll-snap-type: none;
}

.hidden {
    opacity: 0;
}
