@use '../styles/functions.scss' as *;

.container {
    display: flex;
    flex-direction: column;
}

.items-container {
    margin: 0 1rem 1rem;
    max-height: 15rem;
    overflow-y: auto;
}

.quality-filter-field {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;

    column-gap: 4px;
}

.quality-filter-form {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    position: relative;
}

.quality-filter-container:focus-within {
    .quality-filter-input {
        max-height: 4em;
    }
}

.quality-filter-input {
    flex-grow: 1;
    overflow: hidden;

    height: 100vh;

    max-height: 2em;

    transition: max-height 100ms ease-in-out;
}

.quality-filter-field-limit {
    position: absolute;

    right: 4px;
    bottom: 4px;

    opacity: .4;
}

.quality-filter-search-icon {
    height: 32px;
    width: 32px;

    fill: hulder-color('primary');
}

.add-quality-button {
  background-color: white;

  width: 40px;
  height: 40px;

  svg {
    fill: hulder-color('primary');
  }

  &:not(.in-progress) {
    .add-quality-button-progress {
      circle {
        stroke: hulder-color('primary');
      }
    }
  }
}

.item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.2rem;
    border-bottom: 2px solid hulder-color('primary');
    column-gap: 12px;
}

.search-icon {
    width: 1rem;
}

.itemCheckbox {
    flex: 0 0 auto;
}

.itemLabel {
    color: hulder-color('primary');
    font-size: 14pt;
    cursor: pointer;
    flex-grow: 1;
}

.quality-filter-container {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 1rem 0;
    column-gap: 4px;
    align-items: center;
}

.addButtonIcon {
    display: block;
    fill: hulder-color('primary');
    width: 2rem;
    height: 2rem;
}

.saveContainer {
    margin: 0.5rem 0;
    display: flex;
}

.saveButton {
    margin-left: 1rem;
}

.saveButtonIcon {
    display: block;
    fill: hulder-color('primary');
    width: 2rem;
    height: 2rem;
}

.saveButtonIconGrey {
    fill: #9e9e9e;
}

.deleteButton {
    margin-left: auto;
}