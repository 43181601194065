@use '../styles/functions.scss' as *;

.default {
    color: hulder-color('primary');
    background-color: #ffffff;
    border: unset;
    border-bottom: 2px solid hulder-color('primary');
    border-radius: 0;
    resize: unset;
    font-size: 14pt;
    padding: 0.5rem 0.5rem;
    min-height: 37px;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-family: "Archivo Narrow";
    outline: unset;
    outline-color: #4d90fe;
    box-sizing: border-box;
}

.default::placeholder {
    color: hulder-color('primary');
    opacity: 0.6;
}

.default:-ms-input-placeholder {
    color: hulder-color('primary');
}

.default::-ms-input-placeholder {
    color: hulder-color('primary');
}

.default:focus {
    outline-style: auto;
    outline-width: 5px;
}