@use '../styles/functions.scss' as *;

.icon-button {
  width: 32px;
  height: 32px;

  padding: 4px;
  appearance: none;
  border-radius: 50%;

  transition: background-color 150ms ease;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    background-color: mix(white, hulder-color('primary'), 20%);
  }

  background-color: hulder-color('primary');
  border: none;

  &.transparent {
    background-color: #0000;

    &:hover {
      background-color: #0001;
    }

    svg {
      fill: hulder-color('primary');
    }
  }

  &:disabled {
    background-color: mix(white, hulder-color('grey'), 50%);

    svg {
      fill: mix(rgb(22, 21, 21), hulder-color('grey'), 50%);
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: white;
  }
}

.icon-button {
  .progress > circle {
    stroke: white;
  }
  &.transparent {
    .progress > circle {
      stroke: hulder-color('primary');
    }
  }
}