@use '../styles/functions.scss' as *;

.container {
    display: inline-block;
    position: relative;
    height: 1.3rem;
    margin: 0;
    width: 1.3rem;
    cursor: pointer;
    font-size: 1rem;
}

.checkboxInput {
    padding: 0;
    margin: 0;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    max-height: 0;
    max-width: 0;
    border: none;
    left: 0;
}

.checkboxBox {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    border: 0.15em solid hulder-color('primary');
    border-radius: 0.2rem;
    outline: unset;
    outline-color: #4d90fe;
    box-sizing: border-box;
}

.checkboxCheck {
    content: "";
    position: absolute;
    display: none;
    width: 5px;
    height: 10px;
    border: solid hulder-color('primary');
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: 1px;
    left: 5px;
}

.checkboxInput:focus ~ .checkboxBox {
    outline-style: auto;
    outline-width: 5px;
}

.checkboxInput:checked ~ .checkboxBox .checkboxCheck {
    display: block;
}

.container.disabled .checkboxBox {
    background-color: #eee;
    cursor: unset;
}

.container.disabled .checkboxCheck {
    border-color: #ca6461;
}
